import React, { useEffect } from 'react'
import Router from 'next/router'

import { FooterWithProps, HeaderWithProps } from '../../../frontend'
import { Overlay, ModalRoot, BackgroundImage, SkipLink } from '../../index'
import { useSnippetContent } from '../../../utils/providers'

export default function BaseLayout({ children }) {
  const { image } = useSnippetContent('background_image_snippet') || {}

  useEffect(() => {
    const resetFocus = () => {
      // Make sure the focus goes to a sensible place when switching pages
      document?.body.focus()
    }
    Router.events.on('routeChangeComplete', resetFocus)
    return () => {
      Router.events.off('routeChangeComplete', resetFocus)
    }
  }, [])

  return (
    <>
      <div className="site-wrapper">
        <SkipLink target="#main_content" />
        <HeaderWithProps />
        <main id="main_content" tabIndex={-1}>
          {children}
        </main>
        <FooterWithProps />

        <ModalRoot />
      </div>

      <Overlay />
      {image && <BackgroundImage image={image} />}
    </>
  )
}
